/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired

                //headroom for mobile menu
                $(".header").headroom({
                    offset: 80
                });

                $('.footer-form').submit(function (e) {
                    e.preventDefault();
                    var hasError = 0,
                        pattern = new RegExp("^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$"),
                        form = $(this),
                        formMsgContainer = form.find('div.form_messages');
                    $(this).find('input').removeClass('error');

                    $(this).find('input').each(function () {
                        if ($.trim($(this).val()) === '') {
                            $(this).addClass('error');
                            hasError = 1;
                        } else if ($(this).prop('type') === 'email' && !pattern.test($(this).val())) {
                            $(this).addClass('error');
                            hasError = 1;
                        }
                    });
                    if (!hasError) {
                        formMsgContainer.removeClass('error').removeClass('success').slideUp();
                        data = $(this).serializeArray();
                        data.push({name: 'action', value: 'odeslat_poptavku'});

                        $.ajax({
                            url: forest.ajaxurl,
                            type: 'POST',
                            data: data,
                            dataType: 'JSON'
                        }).success(function (data) {
                            if (data.send === 1) {
                                formMsgContainer.addClass('success').text(data.msg).slideDown();
                                form.find('input').val('');
                            }
                        });
                    } else {
                        formMsgContainer.removeClass('success');
                        formMsgContainer.addClass('error').text(forest.formValidationError).slideDown();
                    }

                });

                $("a[href='#poptavka']").click(function (e) {
                    e.preventDefault();
                    $('html, body').animate({
                        scrollTop: $("#poptavka").offset().top
                    }, 500);
                });

                $('.menu-item-has-children  A').click(function (e) {

                    if (window.innerWidth < 992) {
                        if($(this).closest('ul').hasClass('collapsed')){
                            return true;
                        }
                        e.preventDefault();
                        $(this).toggleClass('opened');
                        var submenu = $(this).closest('li').find('ul.sub-menu').eq(0);
                        if (submenu.find('li').eq(0).hasClass('helper')) {
                        } else {
                            submenu.prepend('<li class="helper col-md-4 col-xs-12"></li>');
                            if (submenu.hasClass('collapsed')) {
                                submenu.find('li.helper').removeClass("col-md-4 col-xs-12");
                            }
                            $(this).clone().text('Vše z kategorie').appendTo(submenu.find('li.helper'));
                        }
                        $(this).closest('li').find('ul.sub-menu').eq(0).stop(false, false).slideToggle();

                    }
                });

                //top navigation on mobile
                $(window).resize(function () {
                    if (window.innerWidth < 992) {

                        $('.navigation .image-collapse:not(.collapsed-image)').each(function () {
                            $(this).addClass('collapsed-image');
                            $(this).prependTo($(this).parent().find('.image-collapse-container > A'));
                        });
                        $('.navigation .sub-menu .sub-menu:not(.collapsed)').each(function () {
                            $(this).addClass('collapsed');
                            $(this).insertAfter($(this).closest('div'));
                        });
                    } else if (window.innerWidth >= 992) {
                        $('.navigation ul').removeAttr('style');
                        $('.navigation a').removeClass('opened');
                        $('.navigation ul li.helper').remove();
                        $('.navigation .collapsed-image').each(function () {
                            $(this).removeClass('collapsed-image');
                            $(this).prependTo($(this).closest('li'));
                        });

                        $('.navigation .sub-menu .sub-menu.collapsed').each(function () {
                            $(this).removeClass('collapsed');
                            $(this).appendTo($(this).parent().find('div:last-of-type'));
                        });

                    }
                }).resize();

                //open mobile search
                $('.search-icon-mobile').click(function (e){
                    e.preventDefault();
                    $('.search-container').stop(false,false).slideToggle();
                });

                //open mobile nav
                $('.burger-menu').click(function(e){
                    e.preventDefault();
                    $('.navigation-container').stop(false,false).slideToggle();

                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                $(".carousel").swipe({

                    swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

                        if (direction === 'left') {
                            $(this).carousel('next');
                        }
                        if (direction === 'right') {
                            $(this).carousel('prev');
                        }

                    },
                    allowPageScroll: "vertical"

                });
            }
        },
        // About us page, note the change from about-us to about_us.
        'single_produkt': {
            init: function () {

            },
            finalize: function () {
                $('.moreinfo span').click(function () {
                    $(this).toggleClass('opened');
                    $('.vice-informaci').slideToggle();
                });

                $(document).on('click', '[data-toggle="lightbox"]', function (event) {
                    event.preventDefault();
                    $(this).ekkoLightbox();
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
